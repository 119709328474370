import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, retry, throwError, timer} from 'rxjs';
import {AuthedUserService} from "../authed-user.service";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

    authFailureCounter = 0;

    constructor(
        private authedUserService: AuthedUserService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retry({
                count: 30,
                delay: (error, attempts) => {
                    if (error.status === 0) {
                        return timer(2000); // retry if status was 502
                    }
                    return throwError(error); // throw error for all other status codes
                },
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 && request.headers.has('Authorization')) {
                    this.authFailureCounter++;
                    if (this.authFailureCounter > 2) {
                        this.authedUserService.removeAuthInfo()
                        //if (this.navigationService.currentPath != '/') {
                        //  this.navigationService.navigate(['/']);
                        //}
                        this.authFailureCounter = 0;
                    }
                }
                if (error.status != 200) {
                    console.log(`API Error (${error.status}): ${error.error?.message}`)
                } else {
                    console.error(`API Error (${error.status}):`)
                    console.error(error)
                }
                throw error;
            })
        );
    }
}
