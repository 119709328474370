import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {AuthedUserService} from "../service/authed-user.service";
import {UserBasicInfo} from "../model/user";
import {NavigationService} from "../service/ui/navigation.service";
import {DrawerService} from "../service/ui/drawer.service";
import {environment} from "../../environments/environment";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AboutDialogComponent} from "./about-dialog.component";
import {Router} from "@angular/router";
import {NotificationService} from "../service/api/notification.service";
import {ConversationService} from 'src/app/service/api/conversation.service';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {DialogService} from "../service/ui/dialog.service";
import {AvatarComponent} from "../user/avatar.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {ContextAction, ContextActions, ContextActionsService} from "../service/context-actions.service";

@Component({
    selector: 'cb-user-drawer',
    template: `
        <div *ngIf="user" class="drawer-container column space-between">
            <div class="upper-section column">
                <!-- User's avatar -->
                <cb-avatar [name]="user.name" [avatarUrl]="user.avatarUrl" [size]="64" style="max-height: 64px"></cb-avatar>
                <div class="name-container">
                    <span class="name">{{ user.name }}</span>
                    <span class="username">&#64;{{ user.username }}</span>
                </div>

                <!--<mat-divider class="divider"></mat-divider>-->

                <ng-container *ngIf="isBigScreen">

                    <a mat-button class="drawer-button row start align-center" (click)="goTo('', true)">
                        <mat-icon>home</mat-icon>
                        <span i18n>Home</span>
                    </a>

                    <a mat-button class="drawer-button row start align-center" (click)="goTo('search', true)">
                        <mat-icon>search</mat-icon>
                        <span i18n>Search</span>
                    </a>

                    <a mat-button class="drawer-button row start align-center" (click)="goTo('meetups', true)">
                        <mat-icon>events</mat-icon>
                        <span i18n>Meetups</span>
                    </a>

                    <a mat-button class="drawer-button row start align-center" (click)="goTo(user.username, true)">
                        <mat-icon>person</mat-icon>
                        <span i18n>My profile</span>
                    </a>

                </ng-container>

                <!--<a mat-button class="drawer-button row start align-center" (click)="goTo('feed', true)">
                  <mat-icon>feed</mat-icon>
                  <span i18n>Feed</span>
                </a>-->

                <!--<a mat-button class="drawer-button row start align-center" (click)="goTo('notifications', true)">
                    <mat-icon>notifications</mat-icon>
                    <span i18n>Notifications</span>
                    <div class="unread-notification-count row center align-center"
                         *ngIf="unreadNotificationCount > 0">
                        <span>{{ unreadNotificationCount }}</span>
                    </div>
                </a>-->

                <a mat-button class="drawer-button row start align-center" (click)="goTo('messages', true)">
                    <mat-icon>email</mat-icon>
                    <span i18n>Messages</span>
                    <div class="unread-notification-count row center align-center"
                         *ngIf="unreadMessageCount > 0">
                        <span>{{unreadMessageCount}}</span>
                    </div>
                </a>

                <a mat-button class="drawer-button row start align-center" (click)="goTo('settings')">
                    <mat-icon>settings</mat-icon>
                    <span i18n>Settings</span>
                </a>

                <div class="action-button row space-between align-center" *ngIf="!actionsHidden">
                    <a mat-button class="post-button row center align-center"
                       [class.fully-rounded]="otherActions.length == 0"
                       (click)="firstAction.action()">
                        <mat-icon style="margin-right: 8px">{{firstAction.icon}}</mat-icon>
                        <span class="action">{{firstActionTitle}}</span>
                    </a>
                    <div class="more-button row center align-center" *ngIf="otherActions.length">
                        <mat-icon [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #menu="matMenu" xPosition="after">
                        @for (action of otherActions; track action.icon; let i = $index) {
                            <mat-divider *ngIf="i > 0"></mat-divider>
                            <a mat-button
                               class="drawer-button menu-button row start align-center"
                               (click)="action.action()">
                                <mat-icon>{{action.icon}}</mat-icon>
                                <span class="action">{{action.name}}</span>
                            </a>
                        }
                    </mat-menu>
                </div>
            </div>
            <div class="lower-section column">
                <a mat-button class="drawer-button row start align-center" (click)="openAboutDialog()">
                    <mat-icon>info</mat-icon>
                    <span i18n>About Ludoya</span>
                </a>
                <a mat-button class="drawer-button row start align-center" (click)="openNewsDialog()">
                    <mat-icon>campaign</mat-icon>
                    <span i18n>What's new</span>
                </a>
                <a mat-button class="drawer-button row start align-center" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span i18n>Log out</span>
                </a>
                <span class="version">Ludoya v{{ environment.version }}</span>
            </div>
        </div>
    `,
    styles: [`
        .drawer-container {
            width: min(295px, calc(75vw - 64px));
            height: calc(100% - 64px);
            padding: 32px 32px 32px 32px;
            background-color: var(--mat-app-background-color);
            border-right: 1px solid color-mix(in srgb, var(--mat-app-text-color) 20%, transparent);
        }

        .divider {
            margin-bottom: 16px;
        }

        .name-container {
            margin-left: 8px;
            margin-top: 8px;
            margin-bottom: 32px;

            .name {
                font-size: 1.1rem;
                font-weight: 700;
            }

            .username {
                font-size: 1.1rem;
                font-weight: 400;
                color: var(--secondary-text-color);
                margin-left: 4px;
            }
        }

        .drawer-button {
            position: relative;
            width: 100%;
            height: 60px;
            padding-top: 8px;
            padding-bottom: 8px;
            //margin-left: -8px;
            justify-content: start;

            // Make button look like a link
            background: none;
            border: none;
            outline: none;
            cursor: pointer;

            color: var(--mat-app-text-color) !important;

            .mat-icon {
                font-size: 1.5rem;
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            font-size: 1.2rem;
            font-weight: 400;
            text-align: start;

            .unread-notification-count {
                position: absolute;
                top: -4px;
                left: -20px;
                background-color: var(--accent-color);
                border-radius: 50%;
                width: 16px;
                height: 16px;
                font-size: 10px;
                letter-spacing: 0.5px;
                font-weight: 500;
                color: var(--mat-app-text-color);
            }
        }

        .action-button {
            height: 46px;
            margin: 16px 8px 8px;
            //padding-left: 16px;
            //padding-right: 16px;
            border-radius: 50px;
            background-color: var(--accent-color);
            cursor: pointer;

            .mat-icon {
                font-size: 1.5rem;
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            .post-button {
                width: 100%;
                height: 100%;
                color: var(--mat-sys-on-accent);
                background-color: var(--accent-color);
                border: none;
                //border-bottom-left-radius: 50px;
                //border-top-left-radius: 50px;
                cursor: pointer;
            }

            .fully-rounded {
                border-radius: 50px;
            }

            .more-button {
                width: 46px;
                color: var(--mat-sys-on-accent);
                border-left: 1px solid var(--mat-sys-on-accent);
                cursor: pointer;
            }

            span {
                font-size: 1.2rem;
                font-weight: 400;
            }
        }

        .menu-button {
            height: 62px;
            width: calc(100% - 16px);
            margin: 0 8px;
            padding-left: 16px;
            padding-right: 16px;

            border: none;

            span {
                font-size: 1.2rem;
                font-weight: 400;
            }
        }

        .action {
            font-size: 1.14rem;
        }

        .version {
            margin-top: 16px;
            font-size: 0.9rem;
            //text-align: end;
        }
    `],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, MatMenuModule, MatDividerModule, AvatarComponent]
})
export class UserDrawerComponent implements OnInit {

    user?: UserBasicInfo
    isBigScreen: boolean = false

    //unreadNotificationCount: number = 0
    unreadMessageCount: number = 0

    constructor(
        private socialAuthService: SocialAuthService,
        private authedUserService: AuthedUserService,
        private drawerService: DrawerService,
        private navigationService: NavigationService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private notificationService: NotificationService,
        private conversationService: ConversationService,
        private dialogService: DialogService,
        private sharedDialogsService: SharedDialogsService,
        private contextActionsService: ContextActionsService,
    ) {
    }

    ngOnInit() {
        this.user = this.authedUserService.getUserData()
        this.router.events.subscribe(() => {
            this.user = this.authedUserService.getUserData()
        })

        this.updateActions(this.contextActionsService.actions)
        this.contextActionsService.actionsObservable.subscribe(actions => {
            this.updateActions(actions)
            this.changeDetectorRef.detectChanges()
        })

        //this.notificationService.getUnreadNotificationCount().subscribe(count => {
        //    this.unreadNotificationCount = count
        //    this.changeDetectorRef.detectChanges()
        //})

        this.conversationService.getUnreadMessageCount().subscribe(count => {
            this.unreadMessageCount = count
            this.changeDetectorRef.detectChanges()
        })

        // Subscribe to drawer state changes to update user data
        this.drawerService.drawerState.subscribe((state) => {
            if (state && !this.user) {
                this.user = this.authedUserService.getUserData()
            }
        })
        this.authedUserService.getAuthedUserObservable().subscribe(user => {
            this.user = user?.userData
        })

        this.breakpointObserver.observe('(min-width: 1000px)').subscribe(result => {
            this.isBigScreen = result.matches
        })
    }

    actionsHidden = false
    firstActionTitle: string = ''
    firstAction: ContextAction
    otherActions: ContextAction[] = []

    updateActions(actions: ContextActions) {
        this.actionsHidden = actions.hide ?? false
        if (actions.actions.length == 0) {
            return
        }
        this.firstActionTitle = actions.title
        this.firstAction = actions.actions[0]
        this.otherActions = actions.actions.slice(1, 4)
    }

    closeDrawer() {
        this.drawerService.close()
    }

    goTo(path: string, isRoot: boolean = false) {
        this.navigationService.navigate([path], isRoot)
        this.closeDrawer()
    }

    openNewsDialog() {
        this.sharedDialogsService.openNewsDialog().subscribe()
    }

    openAboutDialog() {
        let dialogConfig: MatDialogConfig = {
            disableClose: true
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(AboutDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        this.closeDrawer()
    }

    logout() {
        this.user = undefined
        this.authedUserService.removeAuthInfo()
        this.socialAuthService.signOut()
        //this.navigationService.navigate(['/login'], true, {
        //    queryParams: {redirect: decodeURIComponent(this.navigationService.currentPath)}
        //});
        this.navigationService.navigate([''], true);
        this.closeDrawer()
    }

    protected readonly environment = environment;
}
